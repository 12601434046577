// -------------------------------------------------------------------------- //
// Base styles
// -------------------------------------------------------------------------- //

$font-family: "Fredoka", 'vazirmatn', "Lalezar", "tahoma", "Arial",
    -apple-system, blinkmacsystemfont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Open Sans", "Helvetica Neue", sans-serif;

body {
    font-size: calc(10px + 0.33vw);
    font-family: $font-family;
}

h1, h2, h3, h4, h5, h6, p, span, option, select, div {
    font-family: $font-family !important;
}

.main-layout {
    min-height: 100vh;
    padding: 30px;

    @media (max-width: 992px) {
        padding: 30px 15px;
    }
}

.btn {
    cursor: pointer;
}

.rmdp-wrapper {
    .rmdp-week:first-child {
        margin:10px auto 30px;
    }

    .rmdp-week-day {
        color: blue;
    }

    .rmdp-arrow-container {
        align-items: center;
        width: 30px;
        height: 30px;
        padding-bottom: 5px;
        border: 1px solid #999999;
        border-radius: 9px !important;
        transition: all 0.2s ease-in-out;

        &:hover {
            border-color: blue;
            background-color: #FFFFFF;
            transition: all 0.2s ease-in-out;

            i {
                border-color: blue;
                transition: all 0.2s ease-in-out;
            }
        }

        i {
            border-color: #000000;
            transition: all 0.2s ease-in-out;
        }
    }
}

.btn.btn-primary {
    border-color: blue;
    background-color: blue;

    &:hover {
        border-color: darkblue;
        background-color: darkblue;
    }
}

.rmdp-range {
    background-color: blue;
}