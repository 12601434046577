
.edit-reservation-modal {
    .btn.w-100 {
        width: 100%;
        margin-top: 20px;
    }

    .form-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .form-check {
            display: flex;
            align-items: center;

            .form-check-input {
                margin-top: 0;
                margin-bottom: 3px;
            }

            .form-check-label {
                margin-left: 10px;
            }
        }

        &__label {
            margin-bottom: 8px;
            color: #424242;
            font-weight: 400;
            font-size: 18px;
            user-select: none;
        }
    
        .rmdp-day.rmdp-disabled {
            span {
                border-color: transparent !important;
                color: rgba(0, 0, 0, 0.2);
                text-decoration-line: line-through;
            }
        }

        .rmdp-input {
            width: 100%;
            height: 38px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
        }

        input[type="number"] {
            appearance:textfield;
        }
    }

    .cost {
        margin-top: 0;
        margin-bottom: 30px;
        direction: rtl;

        span {
            font-weight: 500;
            text-decoration: underline;
            text-decoration-color: rgb(0, 16, 189);
        }
    }
}
