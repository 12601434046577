// -------------------------------------------------------------------------- //
// Auth module
// -------------------------------------------------------------------------- //

.m-auth {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);

    img {
        object-fit: contain;
        width: 400px;
        max-width: 100%;
        height: 300px;
        margin: 20px auto;
    }

    .btn-lg {
        width: 100%;
        margin-top: 20px;
    }
}
