// -------------------------------------------------------------------------- //
// Dashboard module
// -------------------------------------------------------------------------- //

.m-dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 110px;

    header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 3rem;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(40, 39, 39, 0.3);

        .today-time {
            margin-bottom: 20px;
            text-align: center;
            direction: rtl;
        }

        @media (max-width: 992px) {
            flex-wrap: wrap;
            margin: 0;
            margin-bottom: 30px;

            .m-dashboard__title,
            .today-time {
                order: 2;
                width: 100%;
            }

            .btn {
                order: 1;
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    &__title {
        width: 100%;
        color: #242424;
        font-weight: bold;
        text-align: center;
    }

    &__main-page {
        header {
            flex-direction: row;
        }
    }

    &__room-list {
        padding: 3rem;

        @media (max-width: 992px) {
            padding: 0;
        }
    }

    .add-reservation {
        position: fixed;
        right: 40px;
        bottom: 90px;
        z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 6px;
        padding-bottom: 8px;
        border-color: blue;
        border-radius: 110px;
        background-color: blue;
        box-shadow: 2px 2px 3px #999999;
        font-size: 32px;

        @media (max-width: 992px) {
            right: 30px;
            bottom: 83px;
            width: 50px;
            height: 50px;
            padding-bottom: 10px;
        }
    }

    .navigation-bar {
        position: fixed;
        right: 15px;
        bottom: 15px;
        left: 15px;
        z-index: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-radius: 15px;
        background-color: #FFFFFF;
        box-shadow: 0 3px 20px -3px #999999;

        @media (max-width: 992px) {
            padding: 1rem 0;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: calc(60px - 2rem);
            color: #666666;
            font-weight: 400;
            text-align: center;
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            .content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80%;
                padding: 5px;
                background-color: #FFFFFF;
                transition: all 0.2s ease-in-out;

                @media (max-width: 992px) {
                    flex-direction: column;
                    padding: 8px 5px;
                    transition: all 0.2s ease-in-out;
                }
            }

            @media (max-width: 992px) {
                font-size: 12px;
                transition: all 0.2s ease-in-out;
            }

            span {
                text-decoration: underline;
                transition: all 0.2s ease-in-out;
                text-decoration-color: gray;
            }

            i {
                @media (min-width: 992px) {
                    margin-right: 8px;
                }

                color: blue;
                transition: all 0.2s ease-in-out;
            }

            &:not(:last-child) {
                border-right: 1px dashed #999999;
            }

            &.active {
                .content {
                    border-radius: 15px;
                    background-color: blue;
                    color: #FFFFFF;
                    transition: all 0.2s ease-in-out;
                    text-decoration-color: blue;

                    @media (max-width: 992px) {
                        border-radius: 10px;
                        transition: all 0.2s ease-in-out;
                    }

                    i {
                        color: #FFFFFF;
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
        }
    }

    .c-room-card {
        &:hover {
            box-shadow:0 10px 15px -3px rgba(0,0,0,0.1);
            transition: all 0.2s ease-in-out;
            transform: scale(1.03)
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: calc(100% - 30px);
        margin: 15px 0;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 1px 2px 15px -3px rgba(0, 0, 0, 0.1);
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &__title {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            color: rgba(0,0,0, 0.9);
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }

        &__description {
            color: #212529;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -.006em;
            text-align: center;
        }

        &__cost {
            max-width: 197px;
            color: #212529;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -.006em;
            text-align: center;
        }
    }

    &__todo {
        .todo-list-title {
            padding-top: 30px;
            padding-bottom: 10px;
            border-top: 1px solid #000000;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
        }

        .delete {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            border-radius: 110px;
            cursor: pointer;
        }
    }

    &__todo,
    &__shopping,
    &__add-reservation {
        .btn.w-100 {
            width: 100%;
            margin-top: 20px;
        }

        .form-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .form-check {
                display: flex;
                align-items: center;

                .form-check-input {
                    margin-top: 0;
                    margin-bottom: 3px;
                }

                .form-check-label {
                    margin-left: 10px;
                }
            }

            &__label {
                margin-bottom: 8px;
                color: #424242;
                font-weight: 400;
                font-size: 18px;
                user-select: none;
            }

            .rmdp-day.rmdp-disabled {
                span {
                    border-color: transparent !important;
                    color: rgba(0, 0, 0, 0.2);
                    text-decoration-line: line-through;
                }
            }

            .rmdp-input {
                width: 100%;
                height: 38px;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                margin: 0;
                appearance: none;
            }

            input[type="number"] {
                appearance:textfield;
            }
        }

        .cost {
            margin-top: 0;
            margin-bottom: 30px;
            direction: rtl;

            span {
                font-weight: 500;
                text-decoration: underline;
                text-decoration-color: rgb(0, 16, 189);
            }
        }

        .share-links {
            margin-top: 30px;
            text-align: center;

            .react-share__ShareButton {
                margin-left: 8px;

                svg {
                    border-radius: 6px;
                }
            }
        }
    }

    &__room-single {
        .todo-list-title {
            padding-top: 30px;
            padding-bottom: 10px;
            border-top: 1px solid #000000;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
        }

        .delete {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            border-radius: 110px;
            cursor: pointer;
        }

        .cost {
            margin-top: 0;
            margin-bottom: 30px;
            direction: rtl;

            span {
                font-weight: 500;
                text-decoration: underline;
                text-decoration-color: rgb(0, 16, 189);
            }
        }

        > .row {
            @media (min-width: 992px) {
                margin: 50px 2rem 0 !important;
            }
        }

        &__calendar {
            @media (max-width: 992px) {
                margin-bottom: 30px !important;
            }

            .rmdp-wrapper {
                margin: auto;

                &.loading {
                    opacity: 0.7;
                    pointer-events: none;
                    user-select: none;
                }
            }

            .rmdp-day {
                width: 44px;
                height: 44px;
                cursor: default;
                user-select: none;
                transition: all 0.2s ease-in-out;

                @media (max-width: 992px) {
                    width: 40px;
                    height: 40px;
                }

                @media (max-width: 340px) {
                    width: 33px;
                    height: 33px;
                }

                &:not(.rmdp-day-hidden) span {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    color: #424242;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 17px;
                }

                &.rmdp-selected span:not(.highlight) {
                    box-shadow: 2px 2px 8px 0 #8798AD;
                    transition: all 0.2s ease-in-out;
                }

                .sd.highlight.highlight-red {
                    color: #CC0303;
                }
            }
        }

        .reservation-details {
            &__title {
                display: flex;
                margin: 10px auto 20px;
                padding: 0;
                padding-bottom: 10px;
                border-bottom: 2px solid gray;
                color: #666666;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                text-align: center;

                > i {
                    margin-right: 10px;
                    color: blue;
                }
            }

            &__actions {
                padding: 0 6px;
                border: 1px solid transparent;
                border-radius: 9px;
                font-size: 14px;
                cursor: pointer;

                i {
                    margin-right: 10px;
                }

                &.edit {
                    margin-right: auto;
                    border-color: #424242;
                    color: #424242;
                }

                &.remove {
                    margin-left: auto;
                    border-color: #CC0303;
                    color: #CC0303;
                }
            }

            &__list {
                @media (min-width: 992px) {
                    padding: 15px 30px;
                }

                margin: 0;
                padding: 15px;
                border: 1px dashed blue;
                border-radius: 9px;

                .col-md-12 {
                    padding: 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(#666666, 0.3);
                    }
                }
            }

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 15px 0;

                &__key {
                    margin-right: 8px;
                    color: #424242;
                    font-weight: 300;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: right;
                    text-transform: capitalize;
                }

                &__value {
                    color: #424242;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 22px;

                    .fa-circle-check {
                        color: green;
                        font-size: 18px;
                    }

                    .fa-circle-xmark {
                        color: #CC0303;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &__general-calendar {
        > .row {
            @media (min-width: 992px) {
                margin: 50px 2rem 0 !important;
            }
        }

        &__calendar {
            @media (max-width: 992px) {
                margin-bottom: 30px !important;
            }

            .rmdp-wrapper {
                margin: auto;

                &.loading {
                    opacity: 0.7;
                    pointer-events: none;
                    user-select: none;
                }
            }

            .rmdp-day {
                width: 160px;
                min-height: 160px;
                cursor: default;
                user-select: none;
                transition: all 0.2s ease-in-out;

                @media (max-width: 992px) {
                    width: 48px;
                }

                @media (max-width: 340px) {
                    width: 33px;
                }

                &:not(.rmdp-day-hidden) span {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    color: #424242;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 17px;

                    @media (max-width: 992px) {
                        font-weight: 300;
                        font-size: 10px;
                        line-height: unset;
                    }
                }

                &.rmdp-selected span:not(.highlight) {
                    background-color: #FFFFFF;
                    box-shadow: 2px 2px 8px 0 #8798AD;
                    transition: all 0.2s ease-in-out;
                }

                &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                    background-color: #FFFFFF;
                    color: #424242;
                }

                .sd.highlight.highlight-red {
                    color: #CC0303;
                }

                .booked {
                    color: #333333;
                    text-decoration: line-through;
                    opacity: 0.6;
                }

                .free {
                    color: blue;
                }
            }


            .rmdp-day.rmdp-disabled {
                span {
                    border-color: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    &__shopping {
        margin-bottom: 0;

        .suggests-list {
            display: flex;
            align-items: center;

            .suggests-item {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                background-color: #F1F1F1 !important;
                line-height: 16px;
                cursor: pointer;
            }
        }
    }

    /* Main Ticket Style */
    .ticketContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .ticket {
            width: 100%;
            max-width: 600px;
            border-radius: 12px;
            background-color: #F5F5F5;
            color: #424242;
        }

        /* Ticket Content */
        .ticketTitle {
            padding: 12px 16px 4px;
            font-weight: 700;
            font-size: 1.5rem;
            text-align: center;

            i {
                margin-right: 10px;
            }
        }

        .ticketDetail {
            padding: 4px 16px;
            font-weight: 500;
            font-size: 1.1rem;
        }

        .ticketSubDetail {
            display: flex;
            justify-content: space-between;
            padding: 12px 16px;
            font-size: 1rem;

            .code {
                margin-right: 24px;
                font-weight: 300;
                font-size: 24px;
            }

            .date {
                font-weight: 500;
                font-size: 20px;

                span {
                    margin-left: 8px;
                    font-weight: 300;
                    font-size: 14px;
                }
            }
        }

        /* Ticket Ripper */
        .ticketRip {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .circleLeft {
            width: 12px;
            height: 24px;
            border-radius: 0 12px 12px 0;
            background-color: white;
        }

        .ripLine {
            width: 100%;
            border-top: 3px solid white;
            border-top-style: dashed ;
        }

        .circleRight {
            width: 12px;
            height: 24px;
            border-radius: 12px 0 0 12px;
            background-color: white;
        }

        .extra-cost__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0;

            &.paid {
                text-decoration: line-through;
                opacity: 0.3;
            }

            &.header {
                margin-top: 0;
                padding-bottom: 5px;
                border-bottom: 1px solid #666666;

                .extra-cost__item__key,
                .extra-cost__item__value {
                    font-weight: 500;
                }
            }

            .actions {
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid gray;

                i {
                    font-size: 12px;
                    cursor: pointer;

                    &.edit {
                        color: blue;
                    }

                    &.remove {
                        color: #CC0303;
                    }

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }

            &__key {
                margin-right: 8px;
                color: #424242;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                line-height: 22px;
                text-align: right;
                text-transform: capitalize;
            }

            &__value {
                color: #424242;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

.voucher-modal {
    .modal-header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        border-bottom: none;
        background-color: transparent;
    }
}

.voucher {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 30px auto;
    padding: 10px;

    img {
        width: 70px;
        height: 70px;
    }

    &__room-title {
        width: 95%;
        margin-top: 5px;
        margin-bottom: 20px;
        color: #242424;
        text-align: center;

        strong {
            font-weight: bold;
            font-size: 24px;
        }

        small {
            font-size: 12px;
        }
    }

    .guest {
        width: 85%;
        padding: 0 10px;
        border: 1px dashed #717B45;
    }

    .guest-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        padding-bottom: 5px;
        direction: rtl;

        &:not(:last-child) {
            border-bottom: 1px solid #717B45;
        }

        &__key {
            margin: 0;
            color: #424242;
            font-weight: 400;
            font-size: 12px;
        }

        &__value {
            margin: 0;
            color: #242424;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .divider {
        width: 95%;
        margin-top: -2px;
        border: 2px solid #717B45;
        opacity: 1;
    }

    .host {
        width: 95%;
    }

    .host-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        direction: rtl;

        &__key {
            font-weight: 400;
            font-size: 14px;
        }

        &__value {
            font-weight: 500;
            unicode-bidi: plaintext;
        }
    }

    .description {
        margin: 0;
        padding: 0;
        list-style: none;
        direction: rtl;

        li {
            display: flex;
            align-items: flex-start;

            > p {
                margin-bottom: 10px;
                font-size: 12px;

                strong {
                    display: block;
                    margin-top: 10px;
                    unicode-bidi: plaintext;
                }
            }

            .fa-phone {
                margin-top: 3px;
                margin-left: 10px;
                color: grey;
                font-size: 12px;
            }

            .fa-check {
                margin-top: 3px;
                margin-left: 10px;
                color: green;
            }

            .fa-bell-concierge {
                margin-left: 10px;
                color: rgba(red, 0.9);
            }

            .fa-plus {
                margin-top: 3px;
                margin-left: 10px;
                color: red;
                transform: rotate(45deg);
            }

            .fa-exclamation {
                margin-top: 3px;
                margin-left: 10px;
                color: red;
            }
        }
    }
}
